import API, { AxiosRequestOptions } from './API';

export interface PbiEmbedReportResponse {
  EmbedToken: string|null
  EmbedUrl: string|null
}

export interface PbiSessionModel {
  PbiSessionId: string
  AccountIds: number[]
}

export const getEmbeddedReport = async (
  datasetId: string,
  reportId: string,
  pbiSessionId: string,
  options?: AxiosRequestOptions,
): Promise<PbiEmbedReportResponse> => {
  const response = await API.get<PbiEmbedReportResponse>(
    `/api/v2/pbi/reports/${datasetId}/${reportId}/embedded?pbiSessionId=${pbiSessionId}`,
    options,
  );
  return response.data;
};

export const getEnterpriseSurveysReport = async (
  options?: AxiosRequestOptions,
): Promise<PbiEmbedReportResponse> => {
  const response = await API.get<PbiEmbedReportResponse>(
    '/api/v2/enterprise-broadcast-forms/embeddedReport',
    options,
  );
  return response.data;
};

export const createSession = async (
  accountIds: number[],
  options?: AxiosRequestOptions,
): Promise<PbiSessionModel> => {
  const response = await API.post<PbiSessionModel>(
    '/api/v2/pbi/sessions',
    { accountIds },
    options,
  );
  return response.data;
};
