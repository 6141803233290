import React, { useRef, useEffect } from 'react';
import * as pbi from 'powerbi-client'; // Assuming you've imported the Power BI library
import { PbiEmbedReportResponse } from 'api/PowerBiApi';

const PbiReportEmbed: React.FC<PbiEmbedReportResponse> = ({ EmbedToken, EmbedUrl }) => {
  const embedContainer = useRef<HTMLDivElement>(null);
  const powerbiInstance = useRef<pbi.service.Service | null>(null); // Store the Power BI service instance

  useEffect(() => {
    if (embedContainer.current && EmbedToken && EmbedUrl) {
      // Initialize the Power BI service instance if it hasn't been created yet
      if (!powerbiInstance.current) {
        powerbiInstance.current = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory,
        );
      }

      // Clean up the previous embedded report to avoid conflicts
      powerbiInstance.current.reset(embedContainer.current);

      const embedConfig: pbi.IEmbedConfiguration = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: EmbedToken,
        embedUrl: EmbedUrl,
        settings: {
          background: pbi.models.BackgroundType.Transparent,
          layoutType: pbi.models.LayoutType.Custom,
          customLayout: {
            displayOption: pbi.models.DisplayOption.FitToWidth,
          },
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      };

      powerbiInstance.current.embed(embedContainer.current, embedConfig);
    }

    return () => {
      // Clean up the embedded report when the component unmounts
      if (embedContainer.current && powerbiInstance.current) {
        powerbiInstance.current.reset(embedContainer.current);
      }
    };
  }, [EmbedUrl, EmbedToken]);

  return (
    <div className="pbi-report-container" ref={embedContainer} />
  );
};

export default PbiReportEmbed;
