import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import SideBar from './SideBar';
import MobileNav from './MobileNav';

function AppWrapper({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const location = useLocation();

  // selectively apply the default padding
  // This is a bit of a hack since normally we apply a default padding to all routes.
  // We want to avoid this so that the embedded report can take up the entire space, and refactoring all existing
  // routes to apply the padding themselves rather than here would require significant effort
  const applyDefaultPadding = location.pathname !== '/enterprisesurveysreport'
    && !location.pathname.includes('pbi/reports');

  return (
    <div className="AppWrapper">
      <SideBar open={sidebarOpen} openMenuHandler={setSidebarOpen} />
      <div className="Main">
        <div className={applyDefaultPadding ? 'content' : ''}>{children}</div>
      </div>
      <MobileNav sidebarOpen={sidebarOpen} openMenuHandler={setSidebarOpen} />
    </div>
  )
}

export default AppWrapper;
